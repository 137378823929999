import React from 'react';
import { TDR } from 'tdr-common';
import { BookWithAltPlatformLink } from './book-with-alt-platform-link';
import { BookWithTablzLink } from './book-with-tablz-link';
import styles from './landing-page-card.module.scss';

type LandingPageCardProps = {
	platform: TDR.AlternativePlatform | 'tablz';
	background?: React.ReactNode;
	title: string;
	description?: string;
	linkTo: string;
	onClick?: () => void;
};

export function LandingPageCard({
	platform,
	background,
	title,
	description,
	linkTo,
	onClick
}: LandingPageCardProps) {
	const button = platform === 'tablz' ? (
		<BookWithTablzLink to={linkTo} onClick={onClick} />
	) : (
		<BookWithAltPlatformLink href={linkTo} onClick={onClick} platform={platform} />
	);

	return (
		<div className={styles.Card}>
			{background && <div className={styles.Background}>{background}</div>}
			<div className={styles.Container}>
				<div className={styles.Copy}>
					<span className={styles.Title}>{title}</span>
					<span className={styles.Description}>{description}</span>
				</div>
				{button}
			</div>
		</div>
	);
}
