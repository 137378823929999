import React from 'react';
import { Button } from '../../actions/button/button';
import { Modal, ModalControlProps } from '../modal';

type AreYouStillThereProps = {
	onContinue: () => void;
	onEnd: () => void;
} & Omit<ModalControlProps, 'onOpenChange'>;

export const AreYouStillThere = ({ open, onContinue, onEnd }: AreYouStillThereProps) => {
	return (
		<Modal open={open} onOpenChange={null}>
			<Modal.Header>Are you still there?</Modal.Header>

			<Modal.Body>
				<p>Your checkout session has expired.</p>
				<p>Please resume to continue with your seating selection and booking.</p>
			</Modal.Body>

			<Modal.Footer>
				<Button fullWidth variant='secondary' onClick={onContinue}>
          Resume Session
				</Button>
				<Button fullWidth variant='tertiary' onClick={onEnd}>
          End Session
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
