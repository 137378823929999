import React, { FC } from 'react';
import Styles from './AlternativeBookingOption.module.scss';


interface AlternativeBookingOptionProps {
	href: string;
	label: string;
	selfLabel: string;
	selfPosition: 'left' | 'right';
}

const AlternativeBookingOptions: FC<AlternativeBookingOptionProps> = ({ href, label, selfLabel, selfPosition }) => {
	const activeOption =
        <div className={`${Styles.Option} ${Styles['Option--active']}`}>
        	<span>{selfLabel}</span>
        </div>;

	const inactiveOption =
		<a
			href={href}
			target='_self'
			rel='noreferrer'
			aria-label={label}
			className={`${Styles.Option} ${Styles['Option--inactive']}`}
		>
			<div>
				<span>{label}</span>
			</div>
		</a>;


	return (
		<div className={Styles.Container}>
			{selfPosition === 'left' ? activeOption : inactiveOption}
			{selfPosition === 'left' ? inactiveOption : activeOption}
		</div>
	);
};

export default AlternativeBookingOptions;
