import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { CAROUSEL_SCROLL_POSITION_KEY_DESKTOP } from '../common/constants';
import { Header } from '../components/__design-system/layout/header';
import { Page } from '../components/__design-system/layout/page';
import { SubtitleText } from '../components/__design-system/typography/subtitle';
import { TitleText } from '../components/__design-system/typography/title';
import { BookWithAlternativePlatform, BookWithTablz } from '../components/booking-options';
//import { analytics, EXPERIMENT_VIEWED, getRandomVariant, RMS_EXIT_BUTTON_EXPERIMENT } from '../helpers/analytics';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { RestaurantGuardOutletContext } from '../routes/RestaurantGuard';
import styles from './LandingPage.module.scss';

export const LandingPageBookingOptions = () => {
	const { restaurant, featureFlags } = useOutletContext<RestaurantGuardOutletContext>();

	const [, , resetCarouselPosition] = useSessionStorage(CAROUSEL_SCROLL_POSITION_KEY_DESKTOP, '');
	// const [rmsButtonExperiment] = useSessionStorage(RMS_EXIT_BUTTON_EXPERIMENT.storage_key, {
	// 	variant: getRandomVariant(RMS_EXIT_BUTTON_EXPERIMENT)
	// });

	useEffect(() => {
		// Reset saved scroll position if entering from landing page
		resetCarouselPosition();
	}, []);

	// useEffect(() => {
	// 	analytics.track(EXPERIMENT_VIEWED, {
	// 		experiment: RMS_EXIT_BUTTON_EXPERIMENT.experiment_name,
	// 		variant: rmsButtonExperiment.variant === 'Logo Button'
	// 			? RMS_EXIT_BUTTON_EXPERIMENT.variants.logo_button
	// 			: RMS_EXIT_BUTTON_EXPERIMENT.variants.text_button,
	// 		location: 'Landing Page'
	// 	});
	// }, []);

	/* Flip the landing tiles if feature flag is ON and there is no error fetching the flag */
	const shouldFlipTiles = Boolean(featureFlags?.flipLandingModal);

	const firstOption = shouldFlipTiles ? (
		<BookWithAlternativePlatform />
	) : (
		<BookWithTablz/>
	);
	const secondOption = shouldFlipTiles ? (
		<BookWithTablz/>
	) : (
		<BookWithAlternativePlatform />
	);

	return (
		<Page as='main'>
			<Header variant='landing'>
				<TitleText>{restaurant.displayName}</TitleText>
				<SubtitleText>{restaurant.displayAddress}</SubtitleText>
			</Header>

			<div className={styles.Options}>
				{firstOption}
				{secondOption}
			</div>
		</Page>
	);
};
