import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TDR } from 'tdr-common';
import { useDeletePendingMods } from '../../api/deletePendingModifications';
import { useReservation } from '../../api/getReservation';
import { useRestaurant } from '../../api/getRestaurant';
import { useTable } from '../../api/getTable';
import { useModifyReservation } from '../../api/modifyReservation';
import { URLParams } from '../../common/types';
import { CTAButton } from '../../components/buttons';
import { SquareIconButton } from '../../components/buttons/SquareIconButton';
import { Header } from '../../components/checkout/Header';
import { DiscardChanges } from '../../components/dialog/controlled-dialog/DiscardChanges';
import { FilterIcons } from '../../components/filter-icons/FilterIcons';
import { SubHeader } from '../../components/headers/SubHeader';
import { OrderSummary } from '../../components/modifications/OrderSummary';
import { Skeleton } from '../../components/skeleton/Skeleton';
import { useModificationFlowState } from '../../context/ModificationFlowProvider';
import { CheckoutCTALabel } from '../../context/new/CheckoutContext';
import { epochToDisplayDateShort } from '../../helpers/epochToDisplayDateShort';
import { epochToDisplayTime } from '../../helpers/epochToDisplayTime';
import { getCdnImageUri } from '../../helpers/getCDNURI';
import luxonToFirebaseTimestamp from '../../helpers/luxonToFirebaseTimestamp';
import { trackReservationModifications } from '../../helpers/reservationEventTrackers';
import { ModifyBookingFormData } from '../../routes/BookingGuard';

import {
	ImageCard,
	ImageCardAsset,
	ImageCardHeader,
	ImageCardHeaderPrimary,
	ImageCardHeaderTitle
} from '../../components/image-card/ImageCard';
import styles from '../../layouts/ModificationLayout.module.scss';

export function ConfirmRefundPage() {
	const { reservationId } = useParams<URLParams>();

	const { data: reservation } = useReservation(reservationId);
	const { data: restaurant } = useRestaurant(reservation.restaurantId);

	const { newInvoice, setNewInvoice, hasStartedFlow } = useModificationFlowState();

	const navigate = useNavigate();

	const methods = useFormContext<ModifyBookingFormData>();

	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	const watchTableId = methods.watch('tableId');
	const watchGroupSize = methods.watch('guests');
	const watchEpochInSeconds = methods.watch('epochInSeconds');

	const displayDate = epochToDisplayDateShort(watchEpochInSeconds, restaurant.timezone);
	const displayTime = epochToDisplayTime(watchEpochInSeconds, restaurant.timezone);

	const { data: table, isLoading } = useTable(watchTableId);

	const modifyReservationMutation = useModifyReservation(reservationId);
	const deletePendingModsMutation = useDeletePendingMods(reservationId);

	const imageSrc = getCdnImageUri(table.carouselImagePath, {
		width: 359,
		fit: 'crop',
		dpr: 2
	});

	const handleBackClick = () => {
		deletePendingModsMutation.mutate(reservationId);
		navigate(-1);
	};

	const handleExitClick = () => {
		setIsConfirmOpen(true);
	};

	const handleConfirmExit = () => {
		resetFormAndExit();
		setIsConfirmOpen(false);
	};

	const closeConfirmation = () => {
		setIsConfirmOpen(false);
	};

	const resetFormAndExit = () => {
		methods.reset();
		setNewInvoice(undefined);
		deletePendingModsMutation.mutate(reservationId);
		navigate(`/booking/${reservationId}`);
	};

	const handleConfirmChanges = async () => {
		const updatedPrice = newInvoice.total;
		const updatedGroupSize = watchGroupSize;
		const updatedTimestamp = luxonToFirebaseTimestamp(DateTime.fromSeconds(watchEpochInSeconds));

		const response = await modifyReservationMutation.mutateAsync({
			id: reservationId,
			tableId: table.id,
			price: updatedPrice,
			guests: updatedGroupSize,
			time: updatedTimestamp,
			items: [
				...reservation.items.filter((item) => item.type !== TDR.Reservation.ItemType.Table),
				{
					id: table.id,
					type: TDR.Reservation.ItemType.Table,
					label: table.label,
					tags: table.tags
				}
			]
		});
		if (response.success) {
			navigate(`/booking/${reservationId}`);
			trackReservationModifications({
				reservation,
				itemsChanged: {
					tableName: table.name,
					guests: updatedGroupSize,
					time: updatedTimestamp,
					price: updatedPrice
				}
			});
			toast.success('Changes saved successfully!');
		}
		else {
			const errorMessage = response?.message || 'Opps, something went wrong';
			const sentryErrorID = Sentry.captureException(new Error(`modifyReservation Error: ${errorMessage}`));
			toast.error(errorMessage);
			trackReservationModifications({ reservation, errorMessage, sentryErrorID });
		}
	};

	useEffect(() => {
		if (!hasStartedFlow) {
			navigate(`/booking/${reservationId}`);
		}
	});

	return (
		<>
			<div className={styles.Header}>
				<Header
					title='Payment'
					subtitle='Modify Booking'
					iconLeft={<SquareIconButton variant='back' />}
					iconLeftOnClick={handleBackClick}
					iconRight={<SquareIconButton variant='exit' />}
					iconRightOnClick={handleExitClick}
				/>
			</div>

			<div className={styles.Body}>
				<section className={styles.Section}>
					{isLoading ? (
						<Skeleton width='359px' height='240px' />
					) : (
						<ImageCard height={240} brightness={restaurant.brightness ?? 1} contrast={restaurant.contrast ?? 1}>
							<ImageCardAsset src={imageSrc} alt={table.name} />
							<ImageCardHeader>
								<ImageCardHeaderPrimary>
									<ImageCardHeaderTitle>
										<span>{table.name}</span>
									</ImageCardHeaderTitle>
								</ImageCardHeaderPrimary>
							</ImageCardHeader>
						</ImageCard>
					)}
					<FilterIcons groupSize={watchGroupSize} date={displayDate} time={displayTime} />
				</section>

				<section className={styles.Section}>
					<SubHeader title='Order Summary' />
					<OrderSummary
						invoice={newInvoice as TDR.Invoice}
						restaurant={restaurant}
						guests={watchGroupSize}
						tableId={table.id}
						isLargeGroup={table.supportLargeGroup}
					/>
				</section>
			</div>

			<div className={styles.Footer}>
				<CTAButton
					buttonText={reservation.isLargeGroup ? CheckoutCTALabel.SUBMIT : CheckoutCTALabel.MODIFY}
					disabled={modifyReservationMutation.isPending}
					loading={modifyReservationMutation.isPending}
					onClick={handleConfirmChanges}
				/>
			</div>

			<DiscardChanges
				open={isConfirmOpen}
				onDiscard={handleConfirmExit}
				onClose={closeConfirmation}
				onCancel={closeConfirmation}
			/>
		</>
	);
}
