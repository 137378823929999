import React from 'react';
import styles from './modal-container.module.scss';

export const MODAL_CONTAINER_ID = 'modal-container';

export const ModalContainer = () => {
	return (
		<div id={MODAL_CONTAINER_ID} className={styles.Container} />
	);
};
