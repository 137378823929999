import { useQuery } from '@tanstack/react-query';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { TDR, querySnapshotToArray } from 'tdr-common';

export function useTables(restaurantId: string, includeDisabledTables = false) {

	const query = useQuery({
		enabled: !!restaurantId,
		queryKey: ['restaurantTables', restaurantId, includeDisabledTables],
		queryFn: () => getRestaurantTables(restaurantId, includeDisabledTables)
	});

	return query;
}

export async function getRestaurantTables(
	restaurantId: string,
	includeDisabledTables = false
): Promise<TDR.Table[]> {
	try {
		const db = getFirestore();
		const q = query(
			collection(db, 'Tables'),
			where('restaurantId', '==', restaurantId)
		);

		const querySnapshot = await getDocs(q);
		let allTables: TDR.Table[] = querySnapshotToArray(querySnapshot) || [];

		allTables = allTables.filter(t => t.deleted !== true || t.deleted === undefined);

		if (includeDisabledTables === false) {
			return allTables.filter(t => !(t.disabled === true));
		}
		else {
			return allTables;
		}
	}
	catch (error) {
		console.error('Error getting restaurant tables', error);
		return [];
	}
}
