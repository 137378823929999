import { useState } from 'react';

/** Custom hook for persisting state with session storage. */
export const useSessionStorage = (
	key: string,
	initialValue?: any
): [any, (newValue: any) => void, () => void] => {
	const [value, setValue] = useState(() => {
		try {
			const storedValue = sessionStorage.getItem(key);
			if (storedValue !== null) {
				return JSON.parse(storedValue);
			}
			else if (initialValue !== undefined) {
				// Set initial value in sessionStorage if provided
				sessionStorage.setItem(key, JSON.stringify(initialValue));
				return initialValue;
			}
			return undefined; // If no stored value or initial value, return undefined
		}
		catch {
			// Ignore errors and return undefined
			return undefined;
		}
	});

	const updateValue = (newValue: any) => {
		try {
			sessionStorage.setItem(key, JSON.stringify(newValue));
			setValue(newValue);
		}
		catch {
			// Ignore errors
		}
	};

	const removeValue = () => {
		try {
			sessionStorage.removeItem(key);
			setValue(undefined);
		}
		catch {
			// Ignore errors
		}
	};

	return [value, updateValue, removeValue];
};
