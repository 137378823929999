import { useIsFetching } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { TDR } from 'tdr-common';
import getLargeGroupSettings from '../../api/getLargeGroupSettings';
import { useCheckoutContext } from '../../context/new/CheckoutContext';
import { CheckoutGuardOutletContext } from '../../routes/CheckoutGuard';
import Spinner from '../Spinner';
import { Deposits } from '../__design-system/modal/checkout/deposits';
import { HowDoBookingFeesWork } from '../__design-system/modal/info/booking-fees-info';
import { SubHeader } from '../headers/SubHeader';
import { CouponIcon, GuestCountIcon } from '../icons';
import { InvoiceItem } from './InvoiceItem';
import {
	doesItemHavePerGuestCharges,
	getPromoCodeDiscountItem,
	getTableSelectionFeeItem,
	sumDeferredLineItems,
	sumDepositLineItems,
	sumOtherLineItems,
	sumTaxLineItems
} from './util';

const DEFAULT_DEPOSIT_LABEL = 'Deposit';

export const OrderSummary = () => {
	const { checkoutSession: session, invoice, restaurant } = useOutletContext<CheckoutGuardOutletContext>();
	const { checkoutState } = useCheckoutContext();
	const { lineItems, deposit, subtotalPriceAdjustments } = invoice;
	const isSessionReloading = useIsFetching({
		queryKey: ['checkoutSession']
	});

	const [largeGroupSettings, setLargeGroupSettings] = useState<TDR.LargeGroupSettings>(null);

	const tableFeeItem = getTableSelectionFeeItem(lineItems);
	const promoCodeItem = getPromoCodeDiscountItem(lineItems);

	const depositAmount = Math.max(sumDepositLineItems(lineItems), deposit);
	const depositLabel = lineItems.flatMap(item => item.charges || []).find(charge => charge.target === 'deposit')?.label || DEFAULT_DEPOSIT_LABEL;
	const deferredPayment = sumDeferredLineItems(lineItems);
	const otherAmount = sumOtherLineItems(subtotalPriceAdjustments);
	const taxAmount = sumTaxLineItems(subtotalPriceAdjustments);

	const displayPrice = tableFeeItem.total;

	useEffect(() => {
		if (session?.tableId) {
			getLargeGroupSettings(session?.tableId).then((result) => setLargeGroupSettings(result));
		}
	}, [session?.tableId]);

	return (
		<>
			{!!isSessionReloading ? (
				<ul className='OrderSummary'>
					<Spinner size='xs' />
				</ul>
			) : null}
			{!isSessionReloading && (
				<ul className='OrderSummary'>
					{/* Table Selection Fee, and Promo Code Discount (if any) */}
					<InvoiceItem
						key={tableFeeItem.item.id}
						title='Seating Selection'
						subtitle={doesItemHavePerGuestCharges(tableFeeItem) && <GuestCountIcon guests={session.guests} />}
						amount={Math.max(displayPrice, 0)}
						currency={restaurant.currency}
						iconAfter={<HowDoBookingFeesWork />}
					/>
					{!!promoCodeItem && (
						<InvoiceItem
							key={promoCodeItem?.item.id}
							iconBefore={<CouponIcon />}
							title={promoCodeItem?.item.id}
							amount={Math.abs(Math.min(promoCodeItem?.total, 0))}
							currency={restaurant.currency}
							isNegative
						/>
					)}

					{/* Partnership Discount */}
					{!!invoice.discountAmount && (
						<>
							<InvoiceItem
								title={`${invoice.discountOrigin} Exclusive Discount`}
								amount={-invoice.discountAmount}
								currency={restaurant.currency}
							/>
						</>
					)}

					{/* Processing Fee */}
					{/* {!!reservationInvoiceStripeFee && checkoutInvoice.subtotal > 0 && (
						<InvoiceItem
							title='Processing Fee'
							amount={reservationInvoiceStripeFee}
							iconAfter={<ProcessingFeeInfo />}
						/>
					)} */}

					{/* Subtotal */}
					<InvoiceItem title='Subtotal' amount={invoice.subtotal} currency={restaurant.currency} />

					{/* Food and beverage credit */}
					{/*{foodAndBeverageCredit > 0 && <InvoiceItem title='Redeemable Beverage Credit' amount={foodAndBeverageCredit} isDivided={false} />}*/}

					{/* Taxes */}
					{taxAmount > 0 && (
						<InvoiceItem title='Tax' amount={taxAmount} currency={restaurant.currency} isDivided={false} />
					)}

					{/* Other */}
					{otherAmount === 0 ? null : <InvoiceItem title='Other' amount={otherAmount} currency={restaurant.currency} />}

					{/* Deposit */}
					{checkoutState.isLargeGroup ? (
						<InvoiceItem
							title='Group Booking Deposit'
							iconAfter={<Deposits />
								// <GroupBookingDepositInfo
								// 	currency={restaurant.currency}
								// 	deposit={largeGroupSettings?.bookingDeposit}
								// 	minimumSpend={largeGroupSettings?.minimumSpend}
								// />
							}
							amount={
								largeGroupSettings?.bookingDeposit?.amount
									? largeGroupSettings?.bookingDeposit?.amount *
                    (largeGroupSettings?.bookingDeposit?.perPerson ? session.guests : 1)
									: 0
							}
							currency={restaurant.currency}
						/>
					) : (
						<InvoiceItem
							title= {depositLabel}
							iconAfter={depositLabel === DEFAULT_DEPOSIT_LABEL ? <Deposits />: undefined}
							amount={depositAmount}
							currency={restaurant.currency}
						/>
					)}

					{/* Total */}
					<InvoiceItem title='Due Today' amount={invoice.total} currency={restaurant.currency} isDivided={false} variant='bold' />
					{/* <InvoiceItem title='Total' amount={checkoutInvoice.total} isDivided={!!prevInvoice}/> */}

					{/* Deferred Payment */}
					{deferredPayment > 0 && (
						<InvoiceItem title='Deferred Payment' amount={deferredPayment} currency={restaurant.currency} />
					)}

					{/* Previous invoices (for modifications) */}
					{/* ===================================== */}

					{/* {!prevInvoice || prevInvoice.total === 0 || !showingChanges ? null : (
						<InvoiceItem title='Existing Payment' amount={prevInvoice.total} />
					)} */}

					{/* {!prevInvoice ? null : showingChanges ? (
						modificationType !== 'cancel' && (
							<InvoiceItem
								title={payable < 0 ? 'Refund' : 'Amount Due'}
								amount={Math.abs(payable)}
								isBold
							/>
						)
					) : (
						<InvoiceItem title='Amount Paid' amount={total} />
					)} */}
				</ul>
			)}
		</>
	);
};

export const DemoOrderSummary = () => {
	const { checkoutSession: session, invoice, restaurant } = useOutletContext<CheckoutGuardOutletContext>();
	const { subtotalPriceAdjustments } = invoice;

	const [largeGroupSettings, setLargeGroupSettings] = useState<TDR.LargeGroupSettings>(null);

	const taxAmount = sumTaxLineItems(subtotalPriceAdjustments);

	useEffect(() => {
		if (session?.tableId) {
			getLargeGroupSettings(session?.tableId).then((result) => setLargeGroupSettings(result));
		}
	}, [session?.tableId]);

	return (
		<section>
			<SubHeader title='Order Summary' />

			<ul className='OrderSummary'>
				{/* Minimum Spend */}
				<InvoiceItem
					title='Minimum Spend'
					amount={
						largeGroupSettings?.bookingDeposit?.amount
							? largeGroupSettings?.bookingDeposit?.amount *
                (largeGroupSettings?.bookingDeposit?.perPerson ? session.guests : 1)
							: 0
					}
					currency={restaurant.currency}
					subtitle={session?.guests ? `${session?.guests} guests` : undefined}
				/>

				{/* Taxes */}
				{<InvoiceItem title='Tax' amount={taxAmount} currency={restaurant.currency} isDivided={true} />}

				{/* Total */}
				<InvoiceItem title='Total' amount={invoice.total} currency={restaurant.currency} />
			</ul>
		</section>
	);
};
