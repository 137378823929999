import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Page } from '../components/__design-system/layout/page';
import { AnimatedImageGallery } from '../components/animated-image-gallery/AnimatedImageGallery';
import { BookingOptionTile } from '../components/booking-options';
import { RightArrowIcon } from '../components/booking-options/platform/BookWithTablz';
import { getPatioSection } from '../helpers/getPatioSection';
import { mostCompatibleGroupSize } from '../helpers/groupSIze';
import { RestaurantGuardOutletContext } from '../routes/RestaurantGuard';
import styles from './LandingPage.module.scss';

export const LandingPageSeatingOptions = () => {
	const { restaurant, tables, featureFlags } = useOutletContext<RestaurantGuardOutletContext>();

	const patioSection = getPatioSection(tables);

	const groupSize = mostCompatibleGroupSize(tables);
	const defaultPath = featureFlags?.autoSelectGroupSize ? `explore/filters?groupSize=${groupSize}` : 'explore/filters';
	const findTableLink = `/${restaurant.slug}/${defaultPath}`;

	let patioLink = '';
	if (patioSection) {
		patioLink = `/${restaurant.slug}/explore/${patioSection.slug}`;
	}
	if (!!restaurant.settings?.alternativeBookingOption?.label) {
		patioLink = `/${restaurant?.settings?.alternativeBookingOption?.href}?skip=true`;
	}

	return (
		<Page as='main'>
			<div className={styles.Options}>
				<BookingOptionTile
					title={restaurant.settings?.alternativeBookingOption?.selfLabel || 'Sit Inside...'}
					backgroundImage={<AnimatedImageGallery restaurant={restaurant} tables={tables} staticImage />}
					icon={<RightArrowIcon />}
					linkTo={findTableLink}
					ariaLabel={restaurant.settings?.alternativeBookingOption?.selfLabel || 'Sit Inside'}
					flex={1}
					justifyStart
				/>

				<BookingOptionTile
					title={restaurant.settings?.alternativeBookingOption?.label || 'Sit Outside...'}
					backgroundImage={<AnimatedImageGallery restaurant={restaurant} tables={tables} staticPatioImage />}
					icon={<RightArrowIcon />}
					linkTo={patioLink}
					ariaLabel={restaurant.settings?.alternativeBookingOption?.label || 'Sit Outside'}
					flex={1}
					justifyStart
				/>
			</div>
		</Page>
	);
};
