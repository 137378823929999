import React from 'react';
import { InternalLink } from '../actions/internal-link/internal-link';
import { Logo } from '../logo/logo';

const DEFAULT_LABEL_PREFIX = 'Upgrade seating with';

interface BookWithTablzLinkProps {
	to: string;
	onClick: () => void;
	labelPrefix?: string;
}

export const BookWithTablzLink = ({ to, onClick, labelPrefix = DEFAULT_LABEL_PREFIX }: BookWithTablzLinkProps) => {
	const logo = <Logo src={'../../../images/poweredBy/tablz-pink.svg'} alt={'Tablz'} />;

	return (
		<InternalLink
			variant='secondary'
			fullWidth
			onClick={onClick}
			to={to}
			endIcon={logo}
			aria-label={'Upgrade seating with Tablz'}
		>
			{labelPrefix}
		</InternalLink>
	);
};
