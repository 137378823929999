import { PaymentElement } from '@stripe/react-stripe-js';
import { StripeError, StripePaymentElementChangeEvent, StripePaymentElementOptions } from '@stripe/stripe-js';
import React, { useState } from 'react';
import Stripe from 'stripe';
import { PrefilledCardInput } from './PrefilledCardInput';

type PaymentInputProps = {
	stripePaymentMethod?: Stripe.PaymentMethod;
	promoCode?: string;
	setIsPaymentInfoComplete: React.Dispatch<React.SetStateAction<boolean>>;
	onError: (e: StripeError) => void;
};

export const PaymentInput = ({
	stripePaymentMethod,
	promoCode,
	setIsPaymentInfoComplete,
	onError
}: PaymentInputProps) => {
	const [usePrefilledPayment, setUsePrefilledPayment] = useState<boolean>(!!stripePaymentMethod);

	const handlePaymentElementChange = (event: StripePaymentElementChangeEvent) => {
		onError(null);
		setIsPaymentInfoComplete(!event.collapsed && event.complete);
	};

	const handlePaymentCardChange = () => {
		setUsePrefilledPayment(false);
		setIsPaymentInfoComplete(false);
	};

	const options: StripePaymentElementOptions = {
		paymentMethodOrder: ['card', 'apple_pay', 'google_pay'],
		layout: {
			type: 'accordion',
			defaultCollapsed: true
		},
		wallets: {
			applePay: 'auto',
			googlePay: 'auto'
		}
	};

	return usePrefilledPayment && !promoCode ? (
		<PrefilledCardInput stripePaymentMethod={stripePaymentMethod} handleChange={handlePaymentCardChange} />
	) : (
		<PaymentElement
			id='payment-element'
			options={options}
			onLoadError={(event) => console.error({ paymentElementError: JSON.stringify(event) })}
			onChange={handlePaymentElementChange}
		/>
	);
};
