import { collection, getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore';
import { querySnapshotToArray, TDR } from 'tdr-common';

export default async function getReservationInvoice(reservationID: string): Promise<TDR.Invoice | null> {
	const db = getFirestore();

	const q = query(
		collection(db, 'Invoices'),
		where('reservationId', '==', reservationID),
		where('type', '==', 'reservation'),
		where('toAccount.type', '==', 'tk'),
		where('fromAccount.type', '==', 'user'),
		where('status', '!=', 'pending'),
		orderBy('createdAt', 'desc'),
		limit(1)
	);
	const querySnapshot = await getDocs(q);

	if (querySnapshot.empty) {
		return null;
	}

	return querySnapshotToArray(querySnapshot)?.[0] as TDR.Invoice || null;
}
