import React from 'react';
import styles from './logo.module.scss';

type LogoProps = {
	src: string;
	alt: string;
}

export const Logo = ({ src, alt }: LogoProps) => {
	return (
		<img src={src} alt={alt} className={styles.Logo} />
	);
};
