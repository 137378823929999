import { useQuery } from '@tanstack/react-query';
import config from '../common/config';
import { AnnouncementResponse } from '../components/announcements/common';

async function getTableAnnouncement(tableId: string): Promise<AnnouncementResponse> {
	const response = await fetch(`${config.adminApiHost}/tables/${tableId}/announcement`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	});

	const announcement = await response.json();
	return announcement;
}

export function useTableAnnouncement(tableId: string) {
	return useQuery({
		enabled: !!tableId,
		queryKey: ['getTableAnnouncement', tableId],
		queryFn: () => getTableAnnouncement(tableId)
	});
}
