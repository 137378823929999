import React, { PropsWithChildren } from 'react';
import { Container } from './container';
import styles from './header.module.scss';
import { Wrapper } from './wrapper';

interface HeaderProps {
	variant?: 'default' | 'landing';
}

export const Header: React.FC<PropsWithChildren<HeaderProps>> = ({ variant = 'default', children }) => {
	const classes: Record<HeaderProps['variant'], string> = {
		default: styles.Header,
		landing: styles.HeaderLanding
	};

	const innerContent = variant === 'default' ? (
		<Wrapper>
			<Container>
				{children}
			</Container>
		</Wrapper>
	) : (
		<div className={styles.LandingInnerContent}>
			{children}
		</div>
	);

	return (
		<header className={classes[variant]}>
			{innerContent}
		</header>
	);
};
