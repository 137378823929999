import { useQuery } from '@tanstack/react-query';
import { collection, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';
import { TDR, querySnapshotToArray } from 'tdr-common';

export function useTableBySlug(restaurantId: string, tableSlug: string) {

	const query = useQuery({
		enabled: !!tableSlug && !!restaurantId,
		queryKey: ['getTableSlug', restaurantId, tableSlug],
		queryFn: () => getTableSlug(restaurantId, tableSlug)
	});

	return query;
}

export async function getTableSlug(
	restaurantId: string,
	tableSlug: string
): Promise<TDR.Table | null> {
	try {
		const db = getFirestore();
		const q = query(
			collection(db, 'Tables'),
			where('slug', '==', tableSlug),
			where('restaurantId', '==', restaurantId),
			limit(1)
		);
		const querySnapshot = await getDocs(q);
		return querySnapshotToArray(querySnapshot)?.[0] || null;
	}
	catch (error) {
		console.error('Error getting table by slug', error);
		return null;
	}
}
