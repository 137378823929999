import React, { ReactNode } from 'react';
import styles from './container.module.scss';

interface ContainerProps {
	children: ReactNode;
}

export const Container = ({ children }: ContainerProps) => {
	return (
		<div className={styles.Container}>{children}</div>
	);
};
