import * as Dialog from '@radix-ui/react-dialog';
import React, { ReactNode } from 'react';
import { Body } from '../layout/body';
import { Container } from '../layout/container';
import { Footer } from '../layout/footer';
import { Header } from '../layout/header';
import { Wrapper } from '../layout/wrapper';
import { HeaderText } from '../typography/header';
import { MODAL_CONTAINER_ID } from './modal-container';
import styles from './modal.module.scss';

export interface ModalControlProps {
	open: boolean;
	onOpenChange: (open: boolean) => void;
}

interface ModalProps extends ModalControlProps {
	children: React.ReactNode;
}

interface ModalWithTriggerProps {
	trigger: React.ReactNode;
	children: React.ReactNode;
}

export function Modal({ open, onOpenChange, children }: ModalProps) {
	return (
		<Dialog.Root open={open} onOpenChange={onOpenChange}>
			<Dialog.Portal container={document.getElementById(MODAL_CONTAINER_ID)}>
				<Dialog.Overlay className={styles.Overlay} />
				<Dialog.Content className={styles.Content}>{children}</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
}

export function ModalWithTrigger({ trigger, children }: ModalWithTriggerProps) {
	return (
		<Dialog.Root>
			<Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
			<Dialog.Portal container={document.getElementById(MODAL_CONTAINER_ID)}>
				<Dialog.Overlay className={styles.Overlay} />
				<Dialog.Content className={styles.Content}>{children}</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
}

function ModalHeader({ children }: { children: ReactNode }) {
	return (
		<Header>
			<Dialog.Title asChild>
				<HeaderText>{children}</HeaderText>
			</Dialog.Title>
		</Header>
	);
}

function ModalBody({ children }: { children: ReactNode }) {
	return (
		<Wrapper>
			<Container>
				<Body>{children}</Body>
			</Container>
		</Wrapper>
	);
}

function ModalFooter({ children }: { children: ReactNode }) {
	return <Footer>{children}</Footer>;
}

function ModalClose({ children }: { children: ReactNode }) {
	return <Dialog.Close asChild>{children}</Dialog.Close>;
}

Modal.Button = Dialog.Trigger;
Modal.Close = Dialog.Close;
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Close = ModalClose;
