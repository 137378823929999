import { X } from 'lucide-react';
import React, { useEffect } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import styles from './GlobalAnnouncementBanner.module.scss';
import { AnnouncementLevel, AnnouncementResponse } from './common';
import {
	analytics,
	ANNOUNCEMENT_BANNER_EXPERIMENT,
	CLOSE_GLOBAL_BANNER,
	EXPERIMENT_VIEWED,
	getRandomVariant
} from '../../helpers/analytics';

const HAS_CLOSED_GLOBAL_BANNER_KEY = 'has-closed-global-banner';

export const GlobalAnnouncementBanner = ({ announcement }: { announcement: AnnouncementResponse }) => {
	const [hasClosedBanner, setHasClosedBanner] = useSessionStorage(HAS_CLOSED_GLOBAL_BANNER_KEY);
	const [announcementsExperiment] = useSessionStorage(ANNOUNCEMENT_BANNER_EXPERIMENT.storage_key, {
		variant: getRandomVariant(ANNOUNCEMENT_BANNER_EXPERIMENT)
	});

	const handleCloseBanner = () => {
		setHasClosedBanner(true);
		analytics.track(CLOSE_GLOBAL_BANNER, {
			announcement
		});
	};

	useEffect(() => {
		if (!hasClosedBanner) {
			analytics.track(EXPERIMENT_VIEWED, {
				experiment: ANNOUNCEMENT_BANNER_EXPERIMENT.experiment_name,
				variant: announcementsExperiment.variant,
				level: AnnouncementLevel.GLOBAL
			});
		}
	}, [hasClosedBanner]);

	if (hasClosedBanner || announcementsExperiment.variant === ANNOUNCEMENT_BANNER_EXPERIMENT.variants.disabled.name) {
		return null;
	}

	return (
		<div className={styles.Banner}>
			<div></div>
			<span>{announcement.message}</span>
			<button
				className={styles.CloseButton}
				type='button'
				onClick={handleCloseBanner}
				aria-label='Close announcement banner'
			>
				<X size={20} color='gray' />
			</button>
		</div>
	);
};
