import React from 'react';
import styles from './LocalAnnouncementTag.module.scss';

type LocalAnnouncementTagProps = {
	title: string;
	icon: React.ReactNode;
};

export const LocalAnnouncementTag = ({ title, icon }: LocalAnnouncementTagProps) => {
	return (
		<div className={styles.Tag}>
			{icon}
			<span>{title}</span>
		</div>
	);
};
