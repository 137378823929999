import React, { forwardRef } from 'react';
import Spinner from '../../../Spinner';
import styles from '../actions.module.scss';
import { ActionComponentProps } from '../types';

type ButtonProps = {
	isLoading?: boolean;
} & ActionComponentProps &
React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
	const {
		variant = 'primary',
		size = 'md',
		fullWidth = false,
		isLoading,
		startIcon,
		endIcon,
		children,
		...rest
	} = props;

	const classes = `${styles.Button} ${styles[`Button--${variant}`]}  ${styles[`Button--${size}`]} ${
		fullWidth ? styles['Button--fullWidth'] : ''
	}`;

	return (
		<button className={classes} ref={ref} disabled={isLoading} {...rest}>
			{startIcon && startIcon}
			{isLoading ? <Spinner /> : children && <span className={styles.Text}>{children}</span>}
			{endIcon && endIcon}
		</button>
	);
});

Button.displayName = 'Button';
