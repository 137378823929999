import { useQuery } from '@tanstack/react-query';
import { TDR } from 'tdr-common';
import config from '../common/config';

async function getEventsByRestaurant(id: string): Promise<TDR.Event[]> {
	try {
		const response = await fetch(`${config.adminApiHost}/restaurants/${id}/events`);

		if (!response.ok) {
			throw new Error(`Response status: ${response.status}`);
		}

		const events = (await response.json()) as TDR.Event[];
		return events || []; // Ensure an empty array is returned if the response is null/undefined
	}
	catch (error) {
		console.error(error.message);
		return []; // Return an empty array on error
	}
}

export function useEventsByRestaurant(id: string) {
	const query = useQuery({
		queryKey: ['getEvents', id],
		queryFn: () => getEventsByRestaurant(id)
	});
	return query;
}