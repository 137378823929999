import Call from 'iconsax-react/dist/esm/Call';
import ExportSquare from 'iconsax-react/dist/esm/ExportSquare';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { LANDING_PAGE_PLACEHOLDERS } from 'tdr-common';
import {
	analytics,
	LINK_CLICKED_EVENT
} from '../../../helpers/analytics';
import Email from '../../../icons/email.svg';
import { RestaurantGuardOutletContext } from '../../../routes/RestaurantGuard';
//import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { LandingPageCard } from '../../__design-system/landing-page-card/landing-page-card';
import { ProceedWithLogo } from '../../logos/ProceedWithLogo';
import { BookingOptionTileWidgetVersion } from '../BookingOptionTileWidgetVersion';
import styles from './BookWithAlternativePlatform.module.scss';

const SitAnywhereIcon = () => {
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();

	const icons = {
		phone: <Call />,
		email: <img src={Email} alt='email' />
	};

	return (
		<div className={styles.IconContainer}>
			{icons[restaurant?.settings?.alternativePlatform?.vendor] || <ExportSquare />}
		</div>
	);
};

export const BookWithAlternativePlatform = () => {
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();

	//const [rmsButtonExperiment] = useSessionStorage(RMS_EXIT_BUTTON_EXPERIMENT.storage_key);
	//const useVersion = rmsButtonExperiment.variant === 'Logo Button' ? 'new' : 'old';

	const altPlatform = restaurant.settings?.alternativePlatform?.vendor;
	const altPlatformHref = restaurant.settings?.alternativePlatform?.href;

	const contactByPhone = altPlatform === 'phone';
	const contactByEmail = altPlatform === 'email';

	const hasAltPlatform = !contactByPhone && !contactByEmail;

	const descriptionText = {
		phone: (
			<>
        Call <span className={styles.Highlight}>{restaurant?.phoneNumber}</span> to make a standard reservation.
			</>
		),
		email: (
			<>
        Email <span className={styles.Highlight}>{restaurant.contactEmail}</span> to make a standard reservation.
			</>
		)
	};

	const handleClick = () => {
		analytics.track(LINK_CLICKED_EVENT, {
			href: altPlatformHref,
			purpose: 'Redirect to RMS from Landing Page',
			platform: altPlatform
		});
	};

	const title = restaurant.settings?.landingPage?.alternate?.title || LANDING_PAGE_PLACEHOLDERS.alternate.title;
	const description =
    restaurant.settings?.landingPage?.alternate?.description ||
    descriptionText[altPlatform] ||
    LANDING_PAGE_PLACEHOLDERS.alternate.description;
	const callToAction =
    restaurant.settings?.landingPage?.alternate?.callToAction || LANDING_PAGE_PLACEHOLDERS.alternate.callToAction;

	const components = {
		old: (
			<BookingOptionTileWidgetVersion
				title={title}
				description={description}
				icon={<SitAnywhereIcon />}
				platformLogo={hasAltPlatform ? <ProceedWithLogo logo='alternate' /> : null}
				linkTo={altPlatformHref}
				target='_blank'
				onClick={handleClick}
				label={callToAction}
				variant={altPlatform}
			/>
		),
		new: (
			<LandingPageCard
				platform={altPlatform}
				title={title}
				description={description}
				linkTo={altPlatformHref}
				onClick={handleClick}
			/>
		)
	};

	return components['old'];
};
