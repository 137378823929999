import React, { useEffect } from 'react';
import {
	analytics,
	ANNOUNCEMENT_BANNER_EXPERIMENT,
	EXPERIMENT_VIEWED,
	getRandomVariant
} from '../../helpers/analytics';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { AnnouncementCategoryLucideIconMap, AnnouncementLevel, AnnouncementResponse } from './common';
import styles from './LocalAnnouncementBanner.module.scss';

export const LocalAnnouncementBanner = ({ announcement }: { announcement: AnnouncementResponse }) => {
	const [announcementsExperiment] = useSessionStorage(ANNOUNCEMENT_BANNER_EXPERIMENT.storage_key, {
		variant: getRandomVariant(ANNOUNCEMENT_BANNER_EXPERIMENT)
	});

	useEffect(() => {
		analytics.track(EXPERIMENT_VIEWED, {
			experiment: ANNOUNCEMENT_BANNER_EXPERIMENT.experiment_name,
			variant: announcementsExperiment.variant,
			level: AnnouncementLevel.LOCAL
		});
	}, []);

	if (announcementsExperiment.variant === ANNOUNCEMENT_BANNER_EXPERIMENT.variants.disabled.name) {
		return null;
	}

	return (
		<div className={styles.Wrapper}>
			<div className={styles.Inner}>
				<div className={styles.Banner}>
					{AnnouncementCategoryLucideIconMap[announcement.category]}
					<span className={styles.Message}>{announcement.message}</span>
				</div>
			</div>
		</div>
	);
};
