import React from 'react';
import { Modal, ModalWithTrigger } from '../modal';
import { Button } from '../../actions/button/button';
import { InfoIcon } from '../../../icons/info-icon';

export const Deposits = () => {
	return (
		<ModalWithTrigger
			trigger={
				<Button startIcon={<InfoIcon />} variant='link'>
					{null}
				</Button>
			}
		>
			<Modal.Header>Deposit</Modal.Header>
			<Modal.Body>
				<p>
          The deposit is required and charged upfront to confirm your booking, but will be deducted from your final
          bill.
				</p>
				<p>
          Please note, that the deposit is non-refundable past the cancellation window, as per the restaurant&apos;s
          Cancellation policy.
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Modal.Close>
					<Button variant='tertiary' fullWidth>
            Dismiss
					</Button>
				</Modal.Close>
			</Modal.Footer>
		</ModalWithTrigger>
	);
};
