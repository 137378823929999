import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styles from '../actions.module.scss';
import { ActionComponentProps } from '../types';

type InternalLinkProps = LinkProps & ActionComponentProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const InternalLink = (props: InternalLinkProps) => {
	const { variant = 'primary', size = 'md', fullWidth = false, startIcon, endIcon, children, ...rest } = props;

	const classes = `${styles.Button} ${styles[`Button--${variant}`]}  ${styles[`Button--${size}`]} ${fullWidth ? styles['Button--fullWidth'] : ''}`;

	return (
		<Link className={classes} {...rest}>
			{startIcon}
			<span className={styles.Text}>{children}</span>
			{endIcon}
		</Link>
	);
};
