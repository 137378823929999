import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { DATESTR_FMT } from 'tdr-common';
import { useSupportedGroupSizes } from '../../api/availability/availability';
import { HASH_ELEMENT_IDS } from '../../common/hashElementIds';
import { GlobalParameterContext } from '../../context/new/GlobalParameterContext';
import { FILTER_CHANGED, analytics } from '../../helpers/analytics';
import { mockTimeSlots } from '../../helpers/timeslots';
import { AvailabilityGuardContext, SearchFilters } from '../../routes/AvailabilityGuard';
import { TableGuardOutletContext } from '../../routes/TableGuard';
import ServiceSelector from '../ServiceSelector';
import CTAButton from '../buttons/CTAButton';
import { CloseButton } from '../buttons/CloseButton';
import { LeadGenProceedButton } from '../buttons/LeadGenProceedButton';
import Calendar from '../calendar/Calendar';
import { isToday, toLocalJsDate } from '../calendar/Calendar.utils';
import { Header } from '../checkout/Header';
import { PartySizeOption, PartySizeSelector, buildGroupSizeOptions } from '../party-size-selector/PartySizeSelector';
import TimeslotList from '../timeslot-list/TimeslotList';

import styles from '../../layouts/InboundRequestLayout.module.scss';

export const InboundRequest = () => {
	const navigate = useNavigate();

	const { searchString } = useContext(GlobalParameterContext);
	const { restaurant, tables, updateSearchParams, searchFilters } = useOutletContext<AvailabilityGuardContext>();
	const { table: selectedTable, featureFlags } = useOutletContext<TableGuardOutletContext>();
	const { supportedGroupSizes, isFetched } = useSupportedGroupSizes({
		tables: selectedTable? [selectedTable]: tables
	});

	const [partySizeOptions, leadGenOption] = buildGroupSizeOptions(supportedGroupSizes, selectedTable, true);

	const [inboundRequestFilters, setInboundRequestFilters] = useState<SearchFilters>({
		groupSize: leadGenOption?.value ? Number(leadGenOption?.value): undefined,
		date: undefined,
		time: undefined
	});

	useEffect(() => {
		if (isFetched) {
			setInboundRequestFilters({
				...inboundRequestFilters,
				groupSize: leadGenOption?.value ? Number(leadGenOption?.value): undefined
			});
		}
	}, [isFetched]);

	const anyFiltersSet = !!inboundRequestFilters?.date || !!inboundRequestFilters?.time;

	const startTime = featureFlags?.largeGroupInbounds?.options?.startTime;
	const endTime = featureFlags?.largeGroupInbounds?.options?.endTime;

	const leadGenServiceName = 'All Day';
	const leadGenTimeslotMap = mockTimeSlots(startTime, endTime);
	const leadGenTimeslots = sortBy(toPairs(leadGenTimeslotMap[leadGenServiceName].timeSlots), [
		([time]) => time
	]);

	const handleDateChange = (date: DateTime, availabilityPercent: number) => {
		const isSameDay = isToday(toLocalJsDate(date)) && availabilityPercent === 0;

		setInboundRequestFilters({
			...inboundRequestFilters,
			date: date.toFormat(DATESTR_FMT)
		});

		analytics.track(FILTER_CHANGED, {
			filterName: 'date',
			filterValue: date.toISO(),
			sameDay: isSameDay
		});
	};

	const handleTimeslotChange = (newTime: string) => {
		setInboundRequestFilters({
			...inboundRequestFilters,
			time: newTime
		});
		analytics.track(FILTER_CHANGED, {
			filterName: 'timeslot',
			filterValue: newTime
		});
	};

	const handleClearSelections = () => {
		setInboundRequestFilters({
			groupSize: leadGenOption?.value ? Number(leadGenOption?.value): undefined,
			date: undefined,
			time: undefined
		});
		navigate(
			{
				search: ''
			},
			{ replace: true }
		);
	};

	const handleGroupSizeChange = (option: PartySizeOption) => {
		if (!option?.inboundRequest) {
			updateSearchParams(
				{
					groupSize: option?.value ? Number(option.value) : undefined
				},
				{ pathname: `/${restaurant.slug}/explore/filters` }
			);
		}
		else {
			setInboundRequestFilters?.({
				...inboundRequestFilters,
				groupSize: +option.value
			});
		}
	};

	return (
		<>
			<Header
				title='Group Dining Request'
				subtitle={selectedTable ? selectedTable.name : restaurant.name}
				iconRight={
					<CloseButton
						to={{
							pathname: '../',
							search: searchString
						}}
					/>
				}
			/>

			<div className={styles.Body}>
				<PartySizeSelector
					searchFilters={searchFilters}
					onChange={handleGroupSizeChange}
					defaultValue={leadGenOption?.value ? Number(leadGenOption?.value): undefined}
					options={partySizeOptions}
				/>

				<Calendar
					onChange={handleDateChange}
					filters={inboundRequestFilters}
				/>

				<section aria-label='Time Settings Section' id={HASH_ELEMENT_IDS.timeslots}>
					<ServiceSelector
						searchFilters={inboundRequestFilters}
						selectedServiceName={leadGenServiceName}
						servicesMap={leadGenTimeslotMap}
						setSelectedServiceName={() => {}}
						onChange={() => {}}
					/>

					<TimeslotList
						restaurant={restaurant}
						table={selectedTable}
						key={inboundRequestFilters.time}
						date={inboundRequestFilters.date}
						defaultValue={inboundRequestFilters.time}
						service={leadGenServiceName}
						timeslots={leadGenTimeslots}
						onChange={handleTimeslotChange}
					/>
				</section>

				{anyFiltersSet && (
					<CTAButton variant='text' buttonText='Clear Selections' textColor='#F00' onClick={handleClearSelections} />
				)}
			</div>

			<footer className={styles.Footer}>
				<LeadGenProceedButton
					searchFilters={inboundRequestFilters}
				/>
			</footer>
		</>
	);
};
