import React, { useEffect } from 'react';
import { analytics, MODAL_CLOSED_EVENT, MODAL_OPENED_EVENT } from '../../../../helpers/analytics';
import { Button } from '../../actions/button/button';
import { Modal, ModalControlProps } from '../modal';

type AbandonSessionProps = {
	onAbandon: () => void;
	onDismiss: () => void;
} & ModalControlProps;

export const AbandonSession = ({ open, onOpenChange, onAbandon, onDismiss }: AbandonSessionProps) => {
	const properties = { title: 'Abandon Session Modal' };

	const handleOpenChange = (open: boolean) => {
		if (!open) {
			analytics.track(MODAL_CLOSED_EVENT, properties);
		}
		onOpenChange(open);
	};

	const handleDismiss = () => {
		analytics.track(MODAL_CLOSED_EVENT, properties);
		onDismiss();
	};

	useEffect(() => {
		if (open) {
			analytics.track(MODAL_OPENED_EVENT, properties);
		}
	}, [open]);

	return (
		<Modal open={open} onOpenChange={handleOpenChange}>
			<Modal.Header>Second thoughts?</Modal.Header>

			<Modal.Body>
				<p>If you abandon your checkout session, your seating selection will not be held.</p>
				<p>Your details will be saved if you decide to proceed with a different selection.</p>
			</Modal.Body>

			<Modal.Footer>
				<Button fullWidth variant='destructive' onClick={onAbandon}>
          Abandon Session
				</Button>
				<Button fullWidth variant='tertiary' onClick={handleDismiss}>
          Dismiss
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
