// Constants - variables reused in multiple places for tracking events
export const CANCEL_BOOKING_SUCCESS = 'Cancel Booking - Success'; // Cancel Booking Success
export const CANCEL_BOOKING_FAILED_TIME_PASSED = 'Cancel Booking - Failed - Time Passed';
export const CANCEL_BOOKING_FAILED_ERROR = 'Cancel Booking - Failed - Error';
export const CANCEL_BOOKING_FAILED_ALREADY_CANCELLED = 'Cancel Booking - Failed - Already Cancelled';
export const FILTER_CHANGED = 'Filter Change'; // Filter (GroupSize, Calendar, Timeslot) changed
export const TABLES_SEARCHED = 'Tables Searched';
export const SHOW_RESULTS = 'Show Results';
export const MODIFICATION_FAILED = 'Modification Failed'; // Modification Failed
export const MODIFICATION_SUCCESS_CHANGES = 'Modification Success - Changes'; // Modification Success - Changes to Info
export const MODIFICATION_SUCCESS_PAYMENT = 'Modification Success - Payment'; // Modification Success - Changes to Payment
export const PARTNERSHIP_REFERRAL = 'Referred to Partner Link'; // Modification Success - Changes to Payment
export const CLOSE_RESERVATION_MODAL = 'Close Reservation Modal'; // Close Reservation Modal
export const EXPERIMENT_VIEWED = 'Experiment Viewed';
export const MP_OVERLAY_DISMISSED = 'Matterport Overlay Dismissed';
export const MP_TAG_CLICKED = 'Matterport Tag Clicked';
export const CLOSE_GLOBAL_BANNER = 'Global Announcement Banner Closed';

interface ExperimentVariant {
	name: string;
	weight?: number;
}

export interface Experiment {
	storage_key: string;
	experiment_name: string;
	variants: {
		[key: string]: ExperimentVariant;
	};
}

export const MP_OVERLAY_EXPERIMENT: Experiment = {
	storage_key: 'experiment-matterport-overlay',
	experiment_name: 'Matterport Educational Overlay A/B Test',
	variants: {
		enabled: {
			name: 'Overlay Enabled'
		},
		disabled: {
			name: 'Overlay Disabled'
		}
	}
};

export const INVENTORY_NOTICE_EXPERIMENT: Experiment = {
	storage_key: 'experiment-inventory-notices',
	experiment_name: 'Low/No Inventory Notice A/B Test',
	variants: {
		enabled: {
			name: 'Notices Enabled'
		},
		disabled: {
			name: 'Notices Disabled'
		}
	}
};

export const SIMPLIFIED_CTA_EXPERIMENT: Experiment = {
	storage_key: 'experiment-simplified-cta',
	experiment_name: 'simplified cta A/B Test',
	variants: {
		simplified: {
			name: 'Simplified CTA'
		},
		original: {
			name: 'Original CTA'
		}
	}
};

export const RMS_EXIT_BUTTON_EXPERIMENT: Experiment = {
	storage_key: 'experiment-rms-exit-button',
	experiment_name: 'RMS Exit Button A/B Test',
	variants: {
		text_button: {
			name: 'Text Button',
			weight: 0.5
		},
		logo_button: {
			name: 'Logo Button',
			weight: 0.5
		}
	}
};

export const ANNOUNCEMENT_BANNER_EXPERIMENT: Experiment = {
	storage_key: 'announcement-banner-experiment',
	experiment_name: 'Announcement Banner A/B Test',
	variants: {
		enabled: {
			name: 'Announcements Enabled',
			weight: 0.5
		},
		disabled: {
			name: 'Announcements Disabled',
			weight: 0.5
		}
	}
};

// info modals
export const LARGER_GATHERING_INFO_MODAL = 'Larger Gathering Info Modal';
export const FIND_DATE_INFO_MODAL = 'Find Date Info Modal';
export const BOOKING_FEES_INFO_MODAL = 'Booking Fees Info Modal';
export const LINK_CLICKED_EVENT = 'Link Clicked';
export const MODAL_OPENED_EVENT = 'Modal Opened';
export const MODAL_CLOSED_EVENT = 'Modal Closed';

/** helper function for randomly getting an experiment variant */
export function getRandomVariant(experiment: Experiment): string {
	const variants = experiment.variants;
	const variantKeys = Object.keys(variants);
	const weights = variantKeys.map((key) => variants[key].weight || 1);
	const totalWeight = weights.reduce((acc, weight) => acc + weight, 0);

	const random = Math.random() * totalWeight;
	let cumulativeWeight = 0;

	for (let i = 0; i < variantKeys.length; i++) {
		cumulativeWeight += weights[i];
		if (random < cumulativeWeight) {
			return variants[variantKeys[i]].name;
		}
	}

	return null; // This should never happen if weights are correctly set
}
