import React, { ReactNode } from 'react';
import styles from './footer.module.scss';

interface FooterProps {
	children: ReactNode;
}

export const Footer = ({ children }: FooterProps) => {
	return (
		<footer className={styles.Footer}>{children}</footer>
	);
};
