import { motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { TDR } from 'tdr-common';
import { HowDoBookingFeesWork } from '../__design-system/modal/info/booking-fees-info';
import { NoTimeSlotsAvailable } from '../callouts/NoTimeslotsAvailable';
import { TimeslotButton } from './TimeslotButton';
import styles from './TimeslotList.module.scss';

export const NUMBER_OF_COLUMNS = 4;

export type TimeslotListProps = {
	restaurant: TDR.Restaurant;
	table: TDR.Table;
	timeslots: [string, TDR.TimeSlot][];
	defaultValue?: string;
	date?: string;
	onChange: (time: string | null, timeslot: TDR.TimeSlot | null, price?: number) => void;
	service?: string;
	includeBookingFeesInfoModal?: boolean;
	hideFreePricing?: boolean;
};

const TimeslotList = ({
	restaurant,
	table,
	timeslots,
	defaultValue,
	date,
	onChange,
	service,
	includeBookingFeesInfoModal = false,
	hideFreePricing = false
}: TimeslotListProps) => {
	const [selectTime, setSelectTime] = useState<string | undefined>(defaultValue);

	const ref = useRef<HTMLOListElement>();

	const handleOnChange = (time: string, timeslot: TDR.TimeSlot, price?: number) => {
		setSelectTime(time);
		onChange(time, timeslot, price);
	};

	if (!timeslots) {
		return null;
	}

	if (timeslots?.length === 0) {
		return (
			<div style={{ paddingTop: '16px' }}>
				<NoTimeSlotsAvailable restaurant={restaurant} date={date} service={service} tableName={table?.name} />
			</div>
		);
	}

	return (
		<>
			<motion.ol className={styles.timeSlotsContainer} ref={ref}>
				{timeslots.map(([time, slot], index) => (
					<li key={slot?.[0] || `timeslot-${index}`}>
						<TimeslotButton
							restaurant={restaurant}
							table={table}
							idx={index}
							time={time}
							timeslot={slot}
							selected={time === selectTime}
							onClick={(_evt, newTime, newTimeslot, newPrice) => handleOnChange(newTime, newTimeslot, newPrice)}
							hideFreePricing={hideFreePricing}
						/>
					</li>
				))}
			</motion.ol>

			{!hideFreePricing && table && includeBookingFeesInfoModal ? <HowDoBookingFeesWork withLabel/> : null}
		</>
	);
};

export default TimeslotList;
