import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { TDR } from 'tdr-common';
import { analytics, LINK_CLICKED_EVENT, MODAL_OPENED_EVENT } from '../../../../helpers/analytics';
import RestaurantEmailLink from '../../../buttons/RestaurantEmailLink';
import RestaurantPhoneLink from '../../../buttons/RestaurantPhoneLink';
import { Button } from '../../actions/button/button';
import { ExternalLink } from '../../actions/external-link/external-link';
import { Logo } from '../../logo/logo';
import { Modal, ModalControlProps } from '../modal';

type UnavailableDateProps = {
	date: DateTime;
	groupSize: number;
	restaurant: TDR.Restaurant;
} & ModalControlProps;

export const UnavailableDate = ({ date, groupSize, restaurant, open, onOpenChange }: UnavailableDateProps) => {
	const altPlatform = restaurant.settings?.alternativePlatform?.vendor;
	const altPlatformDisplayName = TDR.RMSDisplayNameMap[altPlatform];
	const altPlatformHref = restaurant.settings?.alternativePlatform?.href;

	const logoSrc = `../../../../images/poweredBy/${altPlatform}.svg`;

	const contactByPhone = altPlatform === 'phone';
	const contactByEmail = altPlatform === 'email';
	const useAltPlatform = !contactByPhone && !contactByEmail;

	const handleThirdPartyRMSClick = () => {
		analytics.track(LINK_CLICKED_EVENT, {
			href: altPlatformHref,
			purpose: 'Redirect to RMS from Unavailable Date Modal',
			platform: altPlatform
		});
	};

	const LogoIcon = !altPlatformDisplayName ? null : <Logo src={logoSrc} alt={altPlatformDisplayName} />;

	const getActionText = () => {
		if (contactByPhone && !!restaurant.phoneNumber) {
			return (
				<p>
          Please try another date, or call us at <RestaurantPhoneLink phoneNumber={restaurant.phoneNumber} />.
				</p>
			);
		}

		if (contactByEmail && restaurant.contactEmail) {
			return (
				<p>
          Please try another date, or email us at <RestaurantEmailLink email={restaurant?.contactEmail} />.
				</p>
			);
		}

		if (useAltPlatform) {
			return (
				<p>
          Please try another date
					{!altPlatformDisplayName ? (
						'.'
					) : (
						<>
              , or search on <strong>{altPlatformDisplayName}</strong>.
						</>
					)}
				</p>
			);
		}
	};

	useEffect(() => {
		if (open) {
			analytics.track(MODAL_OPENED_EVENT, { title: 'Unavailable Date Modal' });
		}
	}, [open]);

	return (
		<Modal open={open} onOpenChange={onOpenChange}>
			<Modal.Header>Try another date...</Modal.Header>

			<Modal.Body>
				<p>
          Premium seating for <strong>{groupSize}</strong> guest{groupSize > 1 ? 's' : ''} on{' '}
					<strong>{date?.toFormat('MMMM d')}</strong> is no longer available.
				</p>
				{getActionText()}
			</Modal.Body>

			<Modal.Footer>
				{!altPlatformDisplayName || !useAltPlatform ? null : (
					<ExternalLink
						fullWidth
						variant='secondary'
						href={altPlatformHref}
						endIcon={LogoIcon}
						aria-label={`Find availability on ${altPlatformDisplayName}`}
						onClick={handleThirdPartyRMSClick}
					>
            Find Availability on
					</ExternalLink>
				)}
				<Button fullWidth variant='tertiary' onClick={() => onOpenChange(false)}>
          Dismiss
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
