import { useQuery } from '@tanstack/react-query';
import config from '../common/config';
import { AnnouncementResponse } from '../components/announcements/common';

async function getTableAnnouncementsByRestaurant(id: string): Promise<Record<string, AnnouncementResponse>> {
	const response = await fetch(`${config.adminApiHost}/tables/announcements?restaurantId=${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	});

	const announcements = await response.json();
	return announcements;
}

export function useTableAnnouncementsByRestaurant(id: string) {
	return useQuery({
		enabled: !!id,
		queryKey: ['getTableAnnouncementsByRestaurant', id],
		queryFn: () => getTableAnnouncementsByRestaurant(id)
	});
}
