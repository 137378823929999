import { useQuery } from '@tanstack/react-query';
import config from '../common/config';
import { AnnouncementResponse } from '../components/announcements/common';

async function getRestaurantAnnouncement(id: string): Promise<AnnouncementResponse> {
	const response = await fetch(`${config.adminApiHost}/restaurants/${id}/announcement`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	});

	const announcement = await response.json();
	return announcement;
}

export function useRestaurantAnnouncement(id: string) {
	return useQuery({
		enabled: !!id,
		queryKey: ['getRestaurantAnnouncement', id],
		queryFn: () => getRestaurantAnnouncement(id)
	});
}
