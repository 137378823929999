import { AnimatePresence, motion, MotionConfig, useMotionValue } from 'framer-motion';
import { CalendarDays, ChevronLeft, ChevronRight, Clock } from 'lucide-react';
import React, { useState } from 'react';
import { TDR } from 'tdr-common';
import exportedConfig from '../../common/config';
import { getCdnImageUri } from '../../helpers/getCDNURI';
import { formatBetweenTimes, formatDate, formatDaysOfWeek } from '../events/EventCard';
import styles from './EventCarousel.module.scss';

// const ONE_SECOND = 1000;
// const AUTO_DELAY = ONE_SECOND * 10;
const DRAG_BUFFER = 10;

type EventCarouselProps = {
	items: TDR.Event[];
};

export const EventCarousel = ({ items }: EventCarouselProps) => {
	const [index, setIndex] = useState(0);

	const dragX = useMotionValue(0);

	// For autoplay (if we want)
	// useEffect(() => {
	// 	const intervalRef = setInterval(() => {
	// 		const x = dragX.get();

	// 		if (x === 0) {
	// 			setIndex((prev) => {
	// 				if (prev === items.length - 1) {
	// 					return 0;
	// 				}
	// 				return prev + 1;
	// 			});
	// 		}
	// 	}, AUTO_DELAY);

	// 	return () => clearInterval(intervalRef);
	// }, []);

	const onDragEnd = () => {
		const x = dragX.get();

		if (x <= -DRAG_BUFFER && index < items.length - 1) {
			setIndex((prev) => prev + 1);
		}
		else if (x >= DRAG_BUFFER && index > 0) {
			setIndex((prev) => prev - 1);
		}
	};

	const handlePrev = () => setIndex((prev) => (prev === 0 ? items.length - 1 : prev - 1));

	const handleNext = () => setIndex((prev) => (prev === items.length - 1 ? 0 : prev + 1));

	return (
		<MotionConfig transition={{ duration: 0.7, ease: [0.32, 0.72, 0, 1] }}>
			<div className={styles.Container}>
				<motion.div
					drag='x'
					dragConstraints={{
						left: 0,
						right: 0
					}}
					style={{
						x: dragX
					}}
					animate={{ translateX: `-${index * 100}%` }}
					onDragEnd={onDragEnd}
					className={styles.Items}
				>
					{items.map((event, i) => (
						<EventCard event={event} isActive={index === i} key={event.name} />
					))}
				</motion.div>

				{index > 0 && (
					<AnimatePresence initial={false}>
						<motion.button
							initial={{ opacity: 0 }}
							animate={{ opacity: 0.9 }}
							exit={{ opacity: 0, pointerEvents: 'none' }}
							className={`${styles.Nav} ${styles['Nav--prev']}`}
							whileHover={{ opacity: 1 }}
							onClick={handlePrev}
							tabIndex={0}
						>
							<ChevronLeft />
						</motion.button>
					</AnimatePresence>
				)}

				{index + 1 < items.length && (
					<AnimatePresence initial={false}>
						<motion.button
							initial={{ opacity: 0 }}
							animate={{ opacity: 0.9 }}
							exit={{ opacity: 0, pointerEvents: 'none' }}
							className={`${styles.Nav} ${styles['Nav--next']}`}
							whileHover={{ opacity: 1 }}
							onClick={handleNext}
							tabIndex={0}
						>
							<ChevronRight />
						</motion.button>
					</AnimatePresence>
				)}
			</div>
		</MotionConfig>
	);
};

const EventCard = ({ event, isActive }: { event: TDR.Event; isActive: boolean }) => {
	const [showDetails, setShowDetails] = useState(false);

	let filePath = `/images/events/${event.id}/coverImage.jpg`;
	if (exportedConfig.deployment !== 'production') {
		filePath = `/staging/images/events/${event.id}/coverImage.jpg`;
	}

	const eventInfo = event.displays[0];
	const eventCondition = event.condition?.value;

	const formattedDates = formatDate(eventCondition?.dates || []);
	const formattedDays = eventCondition?.daysOfWeek ? formatDaysOfWeek(eventCondition.daysOfWeek) : null;
	const timeInfo = eventCondition?.betweenTimes ? formatBetweenTimes(eventCondition.betweenTimes) : null;

	// Prioritize 'dates' over 'daysOfWeek'
	const displayDateInfo = formattedDates ?? formattedDays;

	const toggleShowDetails = () => setShowDetails((prev) => !prev);

	const imageVariants = {
		collapsed: { height: 0, opacity: 0 },
		expanded: { height: 240, opacity: 1 }
	};

	const infoVariants = {
		collapsed: { paddingTop: '8px' },
		expanded: { paddingTop: '52px' }
	};

	const descriptionVariants = {
		collapsed: { height: 0, opacity: 0 },
		expanded: { height: 'auto', opacity: 1 }
	};

	return (
		<div className={styles.Card} tabIndex={-1}>
			<motion.img
				variants={imageVariants}
				animate={showDetails ? 'collapsed' : 'expanded'}
				src={getCdnImageUri(filePath)}
				className={styles.Image}
			/>
			<motion.div variants={infoVariants} animate={showDetails ? 'expanded' : 'collapsed'} className={styles.Info}>
				<p className={styles.Name}>{eventInfo.title}</p>

				<p className={styles.Details}>
					<span>
						<CalendarDays size={20} /> {displayDateInfo}
					</span>
					{timeInfo && (
						<>
              •
							<span>
								<Clock size={20} /> {timeInfo}
							</span>
						</>
					)}
				</p>

				<motion.p
					variants={descriptionVariants}
					animate={showDetails ? 'expanded' : 'collapsed'}
					className={styles.Description}
				>
					{eventInfo.description}
				</motion.p>
			</motion.div>

			{eventInfo.description.length > 0 && (
				<button className={styles.Toggle} onClick={toggleShowDetails} tabIndex={isActive ? 0 : -1}>
					{showDetails ? 'Hide' : 'Show'} Details
				</button>
			)}
			{event.href && (
				<a className={styles.Link} href={event.href} target='_blank' rel='noreferrer' tabIndex={isActive ? 0 : -1}>
          Book Now
				</a>
			)}
		</div>
	);
};
