import React from 'react';
import { Modal, ModalWithTrigger } from '../modal';
import { Button } from '../../actions/button/button';
import { InfoIcon } from '../../../icons/info-icon';

interface HowDoBookingFeesWorkProps {
	withLabel?: boolean;
}

export const HowDoBookingFeesWork = ({ withLabel }: HowDoBookingFeesWorkProps) => {
	return (
		<ModalWithTrigger
			trigger={
				<Button startIcon={<InfoIcon />} variant='link' fullWidth={withLabel ? true : false}>
					{withLabel ? 'How do booking fees work?' : null}
				</Button>
			}
		>
			<Modal.Header>Seating Selection</Modal.Header>
			<Modal.Body>
				<p>
          Selecting your seating is a voluntary guest service. This fee is separate from any applicable deposits and
          minimum spend requirements on food and beverage, and is not deducted from your final bill.
				</p>
				<p>
          Please note, that this fee is non-refundable past the cancellation window, as per the restaurant&apos;s
          Cancellation policy.
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Modal.Close>
					<Button variant='tertiary' fullWidth>
            Dismiss
					</Button>
				</Modal.Close>
			</Modal.Footer>
		</ModalWithTrigger>
	);
};
