import React from 'react';
import TickCircle from 'iconsax-react/dist/esm/TickCircle';
import Calendar from 'iconsax-react/dist/esm/Calendar';
import Clock from 'iconsax-react/dist/esm/Clock';
import styles from './StatusTag.module.scss';

type StatusTagProps = {
	show: boolean;
	tableAvailable : boolean;
	onClick? : () => void;
	supportLargeGroup?: boolean;
	conflictingFilter: 'date' | 'time' | ''
} & React.InputHTMLAttributes<HTMLInputElement>;

export const StatusTag = ({ show, tableAvailable, onClick, supportLargeGroup = false, conflictingFilter }: StatusTagProps) => {
	const backgroundColor = tableAvailable ? supportLargeGroup ? '#3F3F3F' : '#00AB55' : 'rgba(0,0,0,0.2)';
	const labelText = tableAvailable ? supportLargeGroup ? 'REQUEST AVAILABILITY' : 'AVAILABLE' : `CHANGE ${conflictingFilter.toUpperCase()}`;

	return (
		<div
			className={`${styles.Tag}${!show ? ` ${styles['Tag--hidden']}`: ''}`}
			style={{ background : backgroundColor }}
			onClick={onClick}
		>
			{labelText === 'AVAILABLE' && <TickCircle variant='Bold' size={20} />}
			{labelText === 'CHANGE DATE' && <Calendar variant='Linear' size={20} />}
			{labelText === 'CHANGE TIME' && <Clock variant='Broken' size={20} />}
			<span>{labelText}</span>
		</div>
	);
};