import React from 'react';
import { ExternalLink } from '../actions/external-link/external-link';
import { TDR } from 'tdr-common';
import { Logo } from '../logo/logo';

const DEFAULT_LABEL_PREFIX = 'Book with';

interface BookWithAltPlatformLinkProps {
	platform: TDR.AlternativePlatform;
	href: string;
	onClick: () => void;
	labelPrefix?: string;
}

export const BookWithAltPlatformLink = ({
	platform,
	href,
	onClick,
	labelPrefix = DEFAULT_LABEL_PREFIX
}: BookWithAltPlatformLinkProps) => {
	const displayName = TDR.RMSDisplayNameMap[platform];
	const logo = <Logo src={`../../../images/poweredBy/${platform}.svg`} alt={displayName} />;

	return (
		<ExternalLink
			variant='secondary'
			fullWidth
			onClick={onClick}
			href={href}
			endIcon={logo}
			aria-label={`${labelPrefix} ${displayName}`}
		>
			{labelPrefix}
		</ExternalLink>
	);
};
