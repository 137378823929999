import React, { HTMLAttributeAnchorTarget } from 'react';
import { Link } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { CTAButton } from '../buttons';
import styles from './BookingOptionTileWidgetVersion.module.scss';

type BookingOptionTileWidgetVersionProps = {
	title: string;
	description?: string;
	linkTo: string;
	target?: HTMLAttributeAnchorTarget;
	onClick?: () => void;
	label: string;
	flex?: number;
	backgroundImage?: React.ReactNode;
	icon?: React.ReactNode;
	justifyStart?: boolean;
	platformLogo?: React.ReactNode;
	variant: 'primary' | 'secondary' | TDR.AlternativePlatform;
};

export const BookingOptionTileWidgetVersion = ({
	title,
	description,
	linkTo,
	target,
	onClick,
	label,
	flex,
	backgroundImage,
	justifyStart,
	platformLogo,
	variant
}: BookingOptionTileWidgetVersionProps) => {
	const buttonColors: Partial<Record<BookingOptionTileWidgetVersionProps['variant'], string>> = {
		primary: '#A8813A',
		opentable: TDR.RMSHexColorMap.OpenTable,
		sevenrooms: TDR.RMSHexColorMap.SevenRooms,
		resy: TDR.RMSHexColorMap.Resy,
		tock: TDR.RMSHexColorMap.Tock
	};

	return (
		<div className={styles.Tile} style={{ flex }}>
			<div className={styles.BackgroundImage}>{backgroundImage}</div>

			<div className={`${styles.Option} ${justifyStart ? styles.OptionJustifyStart : ''}`}>
				<div className={`${styles.OptionText}`}>
					<h2 className={styles.OptionTitle}>{title}</h2>
					<p className={styles.OptionDescription}>{description}</p>
				</div>

				<Link to={linkTo} target={target} style={{ all: 'unset', width: '100%' }} aria-label={label}>
					<CTAButton variant='secondary' buttonText={label} onClick={onClick} backgroundColor={buttonColors[variant] || 'transparent'} />
				</Link>
				{platformLogo}
			</div>

		</div>
	);
};
