import { Hourglass, Star, Zap } from 'lucide-react';
import React from 'react';

export type AnnouncementResponse = { message: string | null; category?: AnnouncementCategory };

export enum AnnouncementLevel {
	LOCAL = 'Local',
	GLOBAL = 'Global'
}

export enum AnnouncementCategory {
	URGENCY = 'Urgency',
	SCARCITY = 'Scarcity',
	POPULARITY = 'Popularity',
	CUSTOM = 'Custom',
}

export const AnnouncementCategoryTitleMap: Record<AnnouncementCategory, string> = {
	[AnnouncementCategory.URGENCY]: 'Booking Fast',
	[AnnouncementCategory.SCARCITY]: 'Last Chance',
	[AnnouncementCategory.POPULARITY]: 'Most Popular',
	[AnnouncementCategory.CUSTOM]: ''
};

const ICON_SIZE = 20;
const ICON_COLOR = '#E9A62A';

const iconProps = {
	size: ICON_SIZE,
	color: ICON_COLOR,
	fill: ICON_COLOR
};

export const AnnouncementCategoryLucideIconMap: Record<AnnouncementCategory, React.ReactNode> = {
	[AnnouncementCategory.URGENCY]: <Zap {...iconProps} />,
	[AnnouncementCategory.SCARCITY]: <Hourglass {...iconProps} />,
	[AnnouncementCategory.POPULARITY]: <Star {...iconProps} />,
	[AnnouncementCategory.CUSTOM]: ''
};
