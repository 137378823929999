import React, { ReactNode } from 'react';
import styles from './body.module.scss';

interface BodyProps {
	children: ReactNode;
}

export const Body = ({ children }: BodyProps) => {
	return (
		<div className={styles.Body}>{children}</div>
	);
};
