import { useIsMutating } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { useSupportedGroupSizes } from '../../api/availability/availability';
import { useSearch } from '../../api/availability/search';
import { MUTATION_KEY } from '../../api/releaseCheckoutSession';
import { AvailabilityGuardContext } from '../../routes/AvailabilityGuard';
import { ShowAllButton } from '../buttons/ShowAllButton';
import { ShowResultsButton } from '../buttons/ShowResultsButton';
import { AvailabilityLevel, TableInventoryNotice } from '../notice/TableInventoryNotice';
import { buildGroupSizeOptions } from '../party-size-selector/PartySizeSelector';
import SearchContainer from './SearchContainer';
import { SHOW_RESULTS, analytics } from '../../helpers/analytics';

const SearchTables = () => {
	const { restaurant, searchFilters, tables, featureFlags } = useOutletContext<AvailabilityGuardContext>();
	const [tableIds, setTablesIds] = useState<string[]>();
	const isReleasing = useIsMutating({
		mutationKey: [MUTATION_KEY],
		exact: true
	});

	const areAnyFiltersSet = !!searchFilters.groupSize || !!searchFilters.date || !!searchFilters.time;

	const { results, isLoading: isSearching } = useSearch({
		params: searchFilters,
		restaurantId: restaurant.id,
		space: tables
	});

	const { supportedGroupSizes, isLoading: isLoadingGroupSizes } = useSupportedGroupSizes({
		tables
	});

	const hasLargeGroupTables = tables?.some((t) => t.supportLargeGroup);
	const hasLeadGenEnabled = !hasLargeGroupTables && featureFlags?.largeGroupInbounds?.enabled && !!restaurant?.phoneNumber;
	const [groupSizeOptions] = buildGroupSizeOptions(supportedGroupSizes, null, hasLeadGenEnabled);

	const isLoading = isSearching || !!isReleasing || isLoadingGroupSizes;

	const getCallToAction = (searchResults: TDR.Availability.searchOutput) => {
		const tableResults = searchResults?.metadata?.totalAvailable;

		return areAnyFiltersSet ? (
			<ShowResultsButton
				isLoading={isLoading}
				tableResults={tableResults}
				searchFilters={searchFilters}
				onClick={() => {
					analytics.track(SHOW_RESULTS, {
						tablesShown: tableResults,
						totalTables: tables.length
					});
				}}
			/>
		) : (
			<ShowAllButton
				searchFilters={searchFilters}
				onClick={() => {
					analytics.track(SHOW_RESULTS, {
						tablesShown: tableResults,
						totalTables: tables.length
					});
				}}
			/>
		);
	};

	useEffect(() => {
		if (results?.metadata?.date && !isLoading) {
			setTablesIds(results.metadata.date);
		}
	}, [results?.metadata?.date]);

	return (
		<SearchContainer
			isLoading={isLoading}
			results={results}
			tableIds={tableIds}
			groupSizeOptions={groupSizeOptions}
			notice={(noticeType: AvailabilityLevel) => <TableInventoryNotice noticeType={noticeType} />}
			footer={getCallToAction(results)}
		/>
	);
};

export default SearchTables;
