import React, { ElementType, PropsWithChildren } from 'react';
import styles from './page.module.scss';

interface PageProps extends PropsWithChildren {
	as?: ElementType; // Specifies the HTML tag or custom component
}

export const Page: React.FC<PropsWithChildren<PageProps>> = ({ children, as: Component = 'div' }) => {
	return <Component className={styles.Page}>{children}</Component>;
};
