import React, { useContext, useEffect } from 'react';
import { PRODUCTION } from '../common/config';
import { FirebaseContext } from '../context/FirebaseContext';
// import FirebaseSecurityRulesTests from '../components/FirebaseSecurityRulesTests';
// import FirebaseSecurityRulesTestsV2 from '../components/FirebaseSecurityRulesTestsV2';

// import styles from './RestaurantListPage.module.scss';
// import getRestaurants from '../api/getRestaurants';
// import { TDR } from 'tdr-common';

export const RestaurantListPage = () => {

	const { initialized } = useContext(FirebaseContext);
	// const [restaurants, setRestaurants] = useState<TDR.Restaurant[]>([]);
	// const [ search, setSearch ] = useState<string>('');
	// const [ filteredRestaurants, setFilteredRestaurants ] = useState<TDR.Restaurant[]>([]);

	// const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	const { value } = e.target;
	// 	setSearch(value);

	// 	const filtered = restaurants.filter(restaurant => {
	// 		return restaurant.name.toLowerCase().includes(value.toLowerCase());
	// 	});

	// 	setFilteredRestaurants(filtered);
	// };

	useEffect(() => {
		if (PRODUCTION || !initialized) {
			return;
		}
		//Disabled for now for security reason, once we impement logins for that page, we will be able to reimplement the list
		// getRestaurants().then(restaurants => {
		// 	setRestaurants(restaurants);
		// });
	}, [initialized]);

	return (
		<div style={{ height:'100%', width:'100%', display: 'flex', flexDirection:'column', alignItems: 'center' }}>

			<img src='/images/poweredBy/tablz.svg' height='auto' style={{ minHeight: '200px' }} width='50%' alt='Tablz' />

			{/*!PRODUCTION && (
				<div style={{ width: 'clamp(325px, 90%, 600px)' }}>
					<label>Search restaurants...</label>
					<input
						type='text'
						className={styles.restaurantSearchInput}
						value={search}
						onChange={handleSearch}
						color='secondary'
					/>
				</div>
			) */}

			{/* <ul className={styles.restaurantList} style={{ display: 'flex', flexDirection:'column', alignItems: 'center', overflow: 'scroll' }}>
				{!PRODUCTION && (!search ? restaurants : filteredRestaurants).map((restaurant) => (
					<li key={restaurant.id}>
						<a href={`/${restaurant.slug}`}>{restaurant.name}</a>
					</li>
				))}
			</ul> */}

			{/* this Sections directly calls all the firestore queries we run thgourh the browser to make sure they are all still allowed (or disallowed) */}
			{/* {initialized && <FirebaseSecurityRulesTestsV2 />} */}
			{/* {initialized && <FirebaseSecurityRulesTests />} */}
		</div>
	);
};