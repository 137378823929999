import React, { useState } from 'react';
import { createSearchParams, useMatch, useNavigate, useOutletContext } from 'react-router-dom';
import { TDR, toLuxon } from 'tdr-common';
import { useCheckoutSession } from '../api/getCheckoutSession';
import { useTable } from '../api/getTable';
import { useReleaseCheckoutSession } from '../api/releaseCheckoutSession';
import { AbandonSession } from '../components/__design-system/modal/checkout/abandon-session';
import { AreYouStillThere } from '../components/__design-system/modal/checkout/are-you-still-there';
import { SquareIconButton } from '../components/buttons/SquareIconButton';
import { CheckoutTimer } from '../components/checkout/CheckoutTimer';
import { Header } from '../components/checkout/Header';
import { baseCheckoutNavConfig, fullCheckoutNavConfig } from '../components/checkout/steps/config';
import { ProgressBar } from '../components/progress-bar/ProgressBar';
import { useCheckoutContext } from '../context/new/CheckoutContext';
import { analytics } from '../helpers/analytics';
import { RestaurantGuardOutletContext } from '../routes/RestaurantGuard';

import styles from './CheckoutLayout.module.scss';

type CheckoutLayoutProps = {
	session: TDR.CheckoutSession.CheckoutSession;
	children: React.ReactNode;
};

export const CheckoutLayout = ({ session, children }: CheckoutLayoutProps) => {
	const { restaurant, featureFlags } = useOutletContext<RestaurantGuardOutletContext>();

	const [isConfirmExitOpen, setIsConfirmExitOpen] = useState(false);

	const {
		data: { checkoutInvoice }
	} = useCheckoutSession(session.id);
	const { checkoutState } = useCheckoutContext();

	const { data: table } = useTable(session.tableId);
	const { mutate: releaseCheckoutSession } = useReleaseCheckoutSession();

	const navigate = useNavigate();

	// TODO: //`
	const navConfig = featureFlags?.enableAddOns ? fullCheckoutNavConfig : baseCheckoutNavConfig;

	const luxonStartDateTime = toLuxon(session.time, restaurant.timezone);

	const requiresCreditCard = restaurant.allowNoShowFees || checkoutInvoice?.total > 0;

	const confirmationRouteMatch = useMatch(`${restaurant.slug}/checkout/${session.id}/confirmation`);
	const match = useMatch(`${restaurant.slug}/checkout/${session.id}/:activeStep`);

	const activeStep = match?.params?.activeStep;
	const totalSteps = navConfig.confirmation.order - (requiresCreditCard ? 0 : 1); // 1 less step when credit card not required
	const progressValue = (navConfig[activeStep]?.order / totalSteps) * 100;

	const handleSessionEnd = () => {
		navigate(`/${restaurant.slug}/explore`, { replace: true });
	};

	const handleSessionContinue = () => {
		// refresh page -> will trigger getCheckoutSession and restart the timer if it has expired
		navigate(0);
	};

	const handleExitClick = () => {
		setIsConfirmExitOpen(true);
	};

	const handleCancelExit = () => {
		setIsConfirmExitOpen(false);
	};

	const handleConfirmExit = () => {
		analytics.track('Checkout Session Exited');
		releaseCheckoutSession({ checkoutId: session.id });
		navigate({
			pathname: `/${restaurant.slug}/explore/${table?.slug ? table.slug + '/availability' : ''}`,
			search: createSearchParams({
				groupSize: session.guests.toString(),
				date: luxonStartDateTime.toFormat('yyyy-LL-dd'),
				time: luxonStartDateTime.toFormat('HH:mm')
			}).toString()
		});
	};

	return (
		<>
			<div className={styles.Container}>
				<div className={styles.Header}>
					<Header
						title={navConfig[activeStep]?.title || ''}
						subtitle='Checkout'
						iconLeft={navConfig[activeStep]?.prev ? <SquareIconButton variant='back' /> : <></>}
						iconLeftOnClick={() => navigate(navConfig[activeStep]?.prev)}
						iconRight={<SquareIconButton variant='exit' />}
						iconRightOnClick={handleExitClick}
					/>

					<CheckoutTimer timeRemaining={checkoutState?.timer?.secondsLeft} />

					<ProgressBar value={progressValue} />
				</div>

				{/* children here is the <Outlet /> passed from the <CheckoutGuard /> */}
				{/* includes the checkout page body and footer */}
				{children}
			</div>

			<AbandonSession
				open={isConfirmExitOpen}
				onOpenChange={setIsConfirmExitOpen}
				onAbandon={handleConfirmExit}
				onDismiss={handleCancelExit}
			/>

			<AreYouStillThere
				open={!confirmationRouteMatch && checkoutState.timer?.secondsLeft === 0}
				onContinue={handleSessionContinue}
				onEnd={handleSessionEnd}
			/>
		</>
	);
};
