import React from 'react';
import styles from '../actions.module.scss';
import { ActionComponentProps } from '../types';

type ExternalLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
ActionComponentProps &
React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ExternalLink = (props: ExternalLinkProps) => {
	const { variant = 'primary', size = 'md', fullWidth = false, startIcon, endIcon, children, ...rest } = props;

	const classes = `${styles.Button} ${styles[`Button--${variant}`]}  ${styles[`Button--${size}`]} ${fullWidth ? styles['Button--fullWidth'] : ''}`;

	return (
		<a target='_blank' rel='noreferrer' className={classes} {...rest}>
			{startIcon}
			<span className={styles.Text}>{children}</span>
			{endIcon}
		</a>
	);
};
